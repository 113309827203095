<template>
  <div class=" dropdown lang-selector d-flex align-items-center pt-4 ms-auto nav-item mx-5 " >
    <a id="navbarDarkDropdownMenuLink" aria-expanded="false" class="nav-link position-fixed dropdown-toggle " data-bs-toggle="dropdown" href="#" role="button" tabindex="0">
      {{route.params.language?.toUpperCase()}}
    </a>
    <ul aria-labelledby="navbarDarkDropdownMenuLink" class="dropdown-menu border-0 rounded-0 ">
      <li v-for="filter in locales.filter(c=> c.code !== route.params.language)" :key="filter.code">
        <button class="dropdown-item text-secondary p-0 px-1" @click="setLanguage(filter.code)">{{filter.language}}
        </button>
      </li>
    </ul>
  </div>

</template>

<script >
import { useRoute, useRouter } from "vue-router";

export default {
  data() {
    return {
      route: useRoute(),
      router: useRouter(),
     
    };
  },
  props: {

    locales: String,
  },

  methods: {
    setLanguage(code) {
      this.$emit("clicked", code);
      this.router.push({ name: this.route.name, params: { language: code } });
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-selector {
  z-index: 1031;
  padding-top: 2.5rem !important;

  .dropdown-toggle {
    text-decoration: none;
    color: var(--bs-nav-link-color);
    opacity: 0.7;

    &:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }

  &:focus-visible{
   
    border: 2px solid var(--brand-dark-grey)!important;
  }

    &::after {
      border: solid var(--brand-grey) !important;
      border-width: 0 2px 2px 0 !important;
      display: inline-block !important;
      padding: 3px !important;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-left: 10px;
      vertical-align: 3px;
    }
  }

  .dropdown-menu {
    height: 50px;
    min-width: 50px !important;
    border: none;
    background: var(--brand-lighter-blue);
    margin-top: 1.6rem !important;

    .dropdown-item {
      &:hover,
      &:focus {
        background: transparent;
        font-weight: bold;
      }
    }
  }
  &:hover {
    font-weight: bold;
  }

  .dropdown-menu .show {
    width: 50px !important;
    inset: auto !important;
  }

  .dropdown-menu[data-popper-placement="bottom-end"] {
    inset: 0px -12px auto auto !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu {
    position: absolute;
  }
}

</style>
