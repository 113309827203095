<template>
  <section id="questions" class="p-5 pt-8">
      <TitleComponent :title="componentData?.title" />
      <h5 class="fw-bold text-left  pb-3" v-if="componentData.description ">
        {{ componentData.description }}
      </h5>
     
      <div class="accordion">
        <div
          class="accordion-item mb-3"
          v-for="(fa, index) in componentData.faqs"
          v-bind:key="index"
        >
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#collapsesOne' + index"
              aria-expanded="false"
            >
              {{ fa?.question }}
            </button>
          </h2>
          <div
            :id="'collapsesOne'  + index"
            class="accordion-collapse collapse"
          >
            <div class="accordion-body text-white" v-html="fa?.answer.html"></div>
          </div>
        </div>
      </div>
   
  </section>
</template>


<script >
export default {
  props: {
    componentData: [],
  },
};
</script>


<style lang="scss">
.accordion-button{
  &:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }

  &:focus-visible{
    
    background: var(--brand-blue) !important;
  }
}

</style>
