<template>
  <div class="text-brand-white text-center text-sm-start image-position banner-img" v-if="componentData?.url" :style=" `background-image: url('${componentData.url}')`">
  </div>
</template>

<script >

import { useRoute } from "vue-router";
export default {
  props: {
    componentData: [],
  },

  data() {
    return {
      route: useRoute(),
      show: false,
      content: [],
    };
  },

};
</script>

<style>
.image-position {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.5s;
}

.banner-img {
  background-image: url("https://media.graphassets.com/q67Ua7V2Rderuy0SLd0f?_gl=1*1frw21i*_ga*MTgzMzAxNzEzMC4xNjU5OTQzNjgy*_ga_G6FYGSYGZ4*MTY2ODA3NjgwMC41NS4xLjE2NjgwNzc2ODguNDEuMC4w");
  height: 400px;
}


</style>
