<template>

    <form class="search-bar" >
<label :data-state="state" for="search" class="extend-label">
  <span class="visually-hidden-focusable">Type search term here</span>
  
  <input class="search-input" type="search"  title="Type search term here" placeholder="Search" @click="state = 'open'" @blur="state='close'"  v-model="searchValue" @change="submitSearch(searchValue)" />
  <i class="bi bi-search  search-icon " @click="submitSearch(searchValue)"  aria-hidden="true"></i>
</label>
</form>

</template>
<script>
import { useRoute, useRouter } from "vue-router";

export default {
  name: "SearchBar",
  props: {},
  data() {
    return {
      searchValue: "",
      show: false,
      state: 'close',
      route: useRoute(),
      router: useRouter(),
    };
  },
  methods: {
    addShowClass() {
      this.show = !this.show;
    },
    submitSearch(navLink) {
      this.router.push(`/${this.route.params.language}/search/?Search=${navLink}`);
    },
  },
};
</script>
<style lang="scss">


.search-bar{

  .search-input{
    background-color: var(--brand-lighter-blue);
}

.extend-label {
  position: relative;
  display: inline-block;
  background-color: var(--brand-lighter-blue);
  padding: 5px 12px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  border-radius: 0;
  box-shadow: 0 0 0 1px var(--brand-blue);
  border-radius: 30px;

  .search-input {
  -webkit-transition: width 1s ease, opacity 0.5s ease 0.5s;
  transition: width 1s ease, opacity 0.5s ease 0.5s;
  opacity: 1;
  width: 180px;
  height: 25px;
  border: 0;
  outline: none;
}

.search-icon{
  position: absolute;
  // top: 9px;
  right: 11px;
  color: var(--brand-black);
  cursor: pointer;
}
}

.extend-label[data-state="close"] {
  border-radius: 30px;
  padding: 5px 5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  box-shadow: none;
  background-color: transparent;

  &::after  {
  width: 0%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

  .search-icon{
  pointer-events: none;
  color: var(--brand-white);
//   top: 1px;
  }

.search-input {
  width: 28px;
  height: 25px;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: opacity 0.5s ease, width 1s ease;
  transition: opacity 0.5s ease, width 1s ease;
  -webkit-appearance: none;
}
}
}



input::-webkit-search-cancel-button {
    padding-right: 18px;
  }

  .extend-label[data-state="open"]{
    margin: 5px 0 5px 0 !important;
 
  }
</style>
