<template>
  <div class="text-center text-sm-start image-position contact-container">
    <TextComponent :componentData="componentData" />

    <div class="row">
      <div
        class="col-md-4 text-center"
        v-for="(contact, index) in componentData.contactDetails"
        :key="index"
      >
        <template
          v-for="(icon, index) in siteIcons.filter(
            (c) => c.title === contact.contactType.toLowerCase()
          )"
          :key="index"
        >
          <img
            class="img-fluid cursor-pointer"
            :src="icon.url"
            alt="icon.title"
          />
        </template>
        <p v-html="contact?.content"></p>

        <div
          class="mb-2"
          v-for="(detail, index) in contact?.contatDetails"
          :key="index"
        >
          <p class="mb-0">{{ detail?.label }}</p>

          <a
            class="text-brand-blue"
            v-if="contact?.contactType === 'Email'"
            :href="`mailto:${detail?.value}`"
            >{{ detail?.value }}</a
          >
          <a
            class="text-brand-blue"
            v-else-if="contact?.contactType === 'Phone'"
            :href="`tel:${detail?.value}`"
            >{{ detail?.value }}</a
          >
          <p v-else>{{ detail?.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    componentData: [],
    siteIcons: [],
  },
};
</script>

<style lang="scss" scoped>
.img-fluid {
  max-width: 20%;
  opacity: 0.3;
}

.contact-container {
  a {
    text-decoration: none;
    &:hover {
      font-weight: bold;
      color: var(--brand-blue) !important;
    }
  }
}
</style>
