<template>
  <template
    v-for="item in menuContent.filter(
      (c) => c.language === this.route.params.language
    )"
    :key="item.GUID"
  >
    <li
      :class="{
        'active-menu': route.path.includes(item.path),
        'd-flex': !item.children.length,
      }"
      v-if="item.displayInHeader"
      class="nav-item dropdown position-relative mx-1"
    >
      <a
        v-if="item.externalUrl"
        :href="item.externalUrl"
        class="nav-link d-inline"
        target="_blank"
        >{{ item.name }}
      </a>

      <a
        v-else-if="item.children.length"
        class="nav-link dropdown-toggle"
        id="navbarDarkDropdownMenuLink"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        href="#"
      >
        {{ item.name }}
      </a>

      <router-link
        v-else
        :to="item.path"
        class="nav-link d-inline"
        @click="setVisibility()"
      >
        {{ item.name }}
      </router-link>
      <ul
        class="dropdown-menu border-0 rounded-0 py-3"
        v-if="item.children.length"
        aria-labelledby="navbarDarkDropdownMenuLink"
        tabindex="0"
      >
        <li
          class="ps-0"
          v-for="subNav in item.children.filter(
            (c) => c.language === this.route.params.language
          )"
          :key="subNav.GUID"
        >
          <!-- <a v-if="subNav.link != null" :href="subNav.link" class="dropdown-item text-secondary py-2" target="_blank">
            <span> {{ subNav.name }}</span>
          </a> -->

          <router-link
            class="dropdown-item text-brand-dark-grey py-2"
            :to="subNav.path"
            @click="setVisibility()"
          >
            <span>
              {{ subNav.name }}
            </span>
          </router-link>
        </li>
      </ul>
    </li>
  </template>
</template>

<script>
// import { useMenuStore } from "@/store/menu";
import { useMenuStore } from "@/store";
import { useRoute } from "vue-router";

export default {
  name: "NavigationBar",
  data() {
    return {
      menu: [],
      route: useRoute(),
      state: "close",
      show: false,
    };
  },

  methods: {
    addShowClass() {
      this.show = !this.show;
    },

    setVisibility() {
      this.$emit("clicked", false);
    },
  },
  props: {
    languageCode: String,
  },

  computed: {
    menuContent() {
      return useMenuStore().menuData;
    },
  },
};
</script>

<style lang="scss" scoped>
.active-menu {
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    height: 4px;
    background: var(--brand-dark-grey);
  }
}
ul.dropdown-menu {
  background-color: var(--brand-white);
  color: var(--brand-black);
  right: 0px;
  left: 0;
  border: none;
  border-radius: 0;
  width: 400px;
  margin-top: 17px;
  &::before {
    content: "";
    position: fixed;
    left: 0;
    width: 100%;
    background: var(--brand-lighter-blue);
    top: 65px;
    height: 205px;
    z-index: -1;
  }

  li {
    padding: 0px;
  }
}
.dropdown-item {
  padding-left: 10px !important;
  color: var(--brand-dark-grey) !important;

  &:hover,
  &:focus {
    background: transparent;
    font-weight: bold;
  }
}

.dropdown-toggle:after {
  border: solid var(--brand-grey) !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 10px;
  vertical-align: 3px;
}

.nav-link {
  color: var(--brand-dark-grey) !important;
  &:hover {
    font-weight: bold;
  }
}

@media screen and (max-width: 1200px) {
  .active-menu {
    &::after {
      content: none;
    }

    .nav-link {
      color: var(--brand-dark-grey) !important;
      font-weight: bold;
    }

    #navmenu {
      padding-top: 1rem !important;
    }
  }
}
</style>
